
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import moment from "moment";
import { LocationQueryRaw } from "vue-router";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";

@Options<LogsViewPage>({
  components: {
    Page,
    ViewFilter,
    LogsView,
  },
})
export default class LogsViewPage extends Vue {
  @Prop({ default: null }) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };

  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  getUtc = getUtc;
  sourceId = -1;

  @Watch("$route.query", { deep: true })
  onQuery() {
    this.getFilterFromQuery();
    (this.$refs.page as LogsView).refresh();
  }

  created() {
    this.getFilterFromQuery();
  }

  getFilterFromQuery() {
    let newFilter: FilterModel = new FilterModel();

    for (let key in this.$route.query) {
      switch (key) {
        case "DateFrom":
          newFilter.DateFrom = moment(this.$route.query[key]).toDate();
          break;
        case "DateTo":
          newFilter.DateTo = moment(this.$route.query[key]).toDate();
          break;
        default:
          newFilter[key] = this.$route.query[key];
      }
    }

    this.filterModel.update(newFilter);
  }

  filterData() {
    this.filterModel.SkipPage = 0;
    this.filterModel.TakePage = 20;
    this.updateQuery();
  }

  updatePaginationQuery(skip: number) {
    this.filterModel.SkipPage = skip;

    this.updateQuery();
  }

  updateQuery() {
    let params: Object = {
      DateFrom: moment(this.filterModel.DateFrom).format("MM.DD.YY HH:mm:ss"),
      DateTo: moment(this.filterModel.DateTo).format("MM.DD.YY HH:mm:ss"),

      source: this.filterModel.source,
      LogType: this.filterModel.LogType,
      LogLevel: this.filterModel.LogLevel,
      Url: this.filterModel.Url,
      SessionToken: this.filterModel.SessionToken,
      Ip: this.filterModel.Ip,
      UserId: this.filterModel.UserId,
      ConnectionId: this.filterModel.ConnectionId,
      RequestId: this.filterModel.RequestId,
      controllerMethod: this.filterModel.controllerMethod,
      ShortMessage: this.filterModel.ShortMessage,
      SkipPage: this.filterModel.SkipPage ? this.filterModel.SkipPage : "0",
      TakePage: this.filterModel.TakePage,
      EndpointId: this.filterModel.EndpointId,
      ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
    };

    let query: LocationQueryRaw = {};

    Object.keys(params).forEach((key) => {
      let value = params[key];

      if (value != null && value != undefined && value.toString() != "") {
        query[key] = value;
      }
    });

    this.$router.push({ name: this.$route.name, query: query });
  }

  setSourceId(value: number) {
    this.sourceId = value;
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
